// required to get $orange variable
@import "~bootstrap/scss/functions"; 
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/bootstrap";

// Prism dependencies
@import "~prismjs/themes/prism-tomorrow.css";
@import "~prismjs/plugins/toolbar/prism-toolbar.css";
@import "~prismjs/plugins/diff-highlight/prism-diff-highlight.css";
@import "~prismjs/plugins/line-highlight/prism-line-highlight.css";
@import "~prismjs/plugins/line-numbers/prism-line-numbers.css";
@import "~prismjs/plugins/match-braces/prism-match-braces.css";


:root, [data-bs-theme=dark] {
  --bs-body-color: #ffffff;
  --bs-body-bg: #020A12;

  --theme-primary: #00F5FF;  
  --theme-secondary: #2C3850;
  --theme-third: #C8C8C9;

  --theme-bg-color: #0E161C;  
  --theme-link-color: #208BB5;
  --theme-link-secondary: #2597C4;
  --theme-link-color-hover: #ffffff;
  
  --theme-footer-color: #131D2C;
}

:root, [data-bs-theme=light] {
  --bs-body-color: #2C3850;
  --bs-body-bg: #F6F6F7;

  --theme-primary: #2D7C99;
  --theme-secondary: #F5F5F5;
  --theme-third: #65696F;
  --theme-title: #343433;

  --theme-bg-color: #FFFFFF;  
  --theme-btn-color: #2D7C99;
  --theme-link-color-hover: #6F6F6F;

  --theme-link-color: #38768E;
  --theme-link-secondary: #19586F;

  --theme-footer-color: #ffffff;
}

/* Override Bootstrap's dark mode styles */
@include color-mode(dark) {

  .bg-secondary {
    background-color: var( --theme-secondary ) !important;
  }

  .link-secondary {
    color: var( --theme-link-secondary ) !important;
  }

  .text-primary {
    color: var( --theme-primary ) !important;
  }
  
  .text-muted {
    color: var( --theme-third ) !important;
  }

  .text-secondary, .copyright-color {
    color: var( --theme-third ) !important;
  }

  .bg-dark{
    background-color: var( --theme-bg-color ) !important;
  }

  .home-link {
    color: var( --theme-link-color-hover );
  }

  .home-link:hover {
    color: var( --theme-link-color );
  }

  a, .nav-link {
    color: var( --theme-primary );
  }

  a:hover, .nav-link:hover {
    color: var( --theme-link-secondary );
  }

  .nav-underline .nav-link:hover, .nav-underline .nav-link:focus {
    border-bottom-color: var( --theme-primary );
  }

  footer {
    background-color: var( --theme-footer-color );
  }

  .statistics-card{
    background-color: var( --theme-secondary );
  }

  .btn-primary {
    color: #000000;
    background-color: var( --theme-primary );
    border-color: var( --theme-primary );
  }

  .btn-outline-primary {
    color: var( --theme-primary );
    border-color: var( --theme-primary );
  }
  
  .btn-secondary {
    color: #ffffff;
    background-color: var( --theme-link-color );
    border-color: var( --theme-link-color );
  }

  .btn-outline-secondary {
    color: var( --theme-link-color );
    border-color: var( --theme-link-color );
  }
  
  .btn-border-color {
    border-color: var( --theme-primary ) !important;
  }

  .btn-outline-primary:hover, 
  .btn-outline-primary:active, 
  .btn-outline-primary:focus-visible, 
  .btn-outline-secondary:hover, 
  .btn-outline-secondary:active, 
  .btn-outline-secondary:focus-visible, 
  .btn-primary:hover, 
  .btn-primary:active, 
  .btn-primary:focus-visible,
  .btn-secondary:hover, 
  .btn-secondary:active, 
  .btn-secondary:focus-visible
  {
    color: #ffffff;
    border-color: var( --theme-secondary );
    background-color: var( --theme-secondary );
  }


  /* ### TestBox usage code tabs ### */
  .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    color: #000000;
    background-color: var( --theme-primary );
    border-color: var( --theme-primary );
  }

  .nav-tabs .nav-link:hover{
    color: #ffffff;
    border-color: var( --theme-secondary );
    background-color: var( --theme-secondary );
  }

  .nav-tabs {
    border-bottom: 1px solid var( --theme-primary );
  }
  /* ### TestBox usage code tabs ### */

  .bg-training-card, .bg-resources-card {
    background-color: var( --theme-footer-color );
  }

  .bg-training-card:hover {
    background-color: #2C3850;

    p, a {
      color: #ffffff !important;
    }

    .bg-secondary {
      background-color: #999DA5 !important;
    }

  }

  .bg-resources-card:hover {
    background-color: #2C3850;

    span, p {
      color: var( --theme-link-secondary ) !important;
    }
  }

  // ### CUSTOMIZE PRISMJS COLORS ###

  // Customize the background color of the code block
  pre[class*="language-"], code[class*="language-"] {
    background-color: var( --theme-footer-color ); 
    color: var( --bs-body-color );
  }

  // Customize specific token colors
  .token.comment, .token.prolog, .token.doctype, .token.cdata {
    color: var( --theme-third )  // Gray color for comments
  }

  .token.custom-install {
    color: var( --theme-primary );  // Blue color for the word "install"
    font-weight: bold;
  }

  // Optionally style line numbers (if using line number plugin)
  .line-numbers .line-numbers-rows {
    border-right: 3px solid var( --theme-primary );;
  }

  .carousel-inner {
    background: var(--theme-footer-color);
  }

}

/* Override Bootstrap's dark mode styles */
@include color-mode(light) {

  a{
    color: var( --theme-link-color );
  }

  .text-primary {
    color: var( --theme-primary ) !important;
  }

  .link-secondary {
    color: var( --theme-link-secondary ) !important;
  }

  .copyright-color {
    color: var( --theme-link-color-hover ) !important;
  }

  .title-color {
    color: var( --theme-title );
  }
  
  .text-muted {
    color: var( --theme-third ) !important;
  }

  .bg-dark{
    background-color: var( --theme-bg-color ) !important;
  }

  .bg-secondary {
    background-color: var( --theme-secondary ) !important;
  }

  .home-link {
    color: var( --theme-primary );
  }

  .nav-link {
    color: var( --theme-link-color-hover );
  }

  .nav-link:hover, .home-link:hover, a:hover {
    color: #444343;
    font-weight: 900;
  }

  .nav-underline .nav-link:hover, .nav-underline .nav-link:focus {
    border-bottom-color: var( --theme-primary );
  }

  footer {
    background-color: var(--theme-footer-color);
  }

  .statistics-card{
    background-color: var( --theme-bg-color );
  }

  .btn-primary {
    color: var( --theme-bg-color );
    background-color: var( --theme-btn-color );
    border-color: var( --theme-btn-color );
  }

  /* ### TestBox usage code tabs ### */
  .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    color: var( --theme-bg-color );
    background-color: var( --theme-btn-color );
    border-color: var( --theme-btn-color );
  }

  .nav-tabs .nav-link:hover{
    color: var( --theme-bg-color );
    border-color: var( --bs-body-color );
    background-color: var( --bs-body-color );
  }

  .nav-tabs {
    border-bottom: 1px solid var( --theme-btn-color );
  }
  /* ### TestBox usage code tabs ### */

  .btn-outline-primary {
    color: var( --theme-btn-color );
    border-color: var( --theme-btn-color );
  }

  .btn-outline-primary:hover, 
  .btn-outline-primary:active, 
  .btn-outline-primary:focus-visible, 
  .btn-primary:hover, 
  .btn-primary:active, 
  .btn-primary:focus-visible 
  {
    color: var( --theme-bg-color );
    border-color: var( --bs-body-color );
    background-color: var( --bs-body-color );
  }

  .bg-training-card, .bg-resources-card {
    background-color: var( --theme-bg-color );
  }

  .bg-training-card:hover, .bg-resources-card:hover {
    box-shadow: 0px 10px 16px #00000029;
  }

  .bg-resources-card:hover {
    span, p {
      color: #444343 !important;
    }
  }

  // ### CUSTOMIZE PRISMJS COLORS ###

  // Customize the background color of the code block
  pre[class*="language-"], code[class*="language-"] {
    background-color: #ECECEF;; 
    color: var( --bs-body-color );
  }

  // Customize specific token colors
  .token.comment, .token.prolog, .token.doctype, .token.cdata {
    color: var( --theme-third )  // Gray color for comments
  }

  .token.custom-install {
    color: var( --theme-primary );  // Blue color for the word "install"
    font-weight: bold;
  }

  // Optionally style line numbers (if using line number plugin)
  .line-numbers .line-numbers-rows {
    border-right: 3px solid var( --theme-primary );
  }

  .carousel-inner {
    background: var(--theme-footer-color);
  }
}